'use strict';

//var base = 'http://local.vbtelecom.com.br/';
var base = 'http://vbtelecom.com.br/';

var ViewModel = function() {
    var self = this;

    self.chooseCity = ko.observable('');
    self.siteTexts = ko.observableArray([]);
    self.address = ko.observableArray([]);
    self.services = ko.observableArray([]);
    self.coverage = ko.observableArray([]);
    self.faq = ko.observableArray([]);
    self.chosenPlan = ko.observable({});
    self.customer = ko.observable({});
    self.planDescription = ko.observable('');
    self.bannerContent = ko.observableArray([]);
    self.bannerPosition = ko.observable();
    self.bannerTimer = null;
    self.bannerHeight = ko.observable($(window).height());

    self.showModal  = ko.observable(false);
    self.popup      = ko.observable();
    self.marginLeft = ko.observable();
    self.marginTop  = ko.observable();
    self.page       = ko.observable(1);
    self.popName    = ko.observable();
    self.popEmail   = ko.observable();
    self.popPhone   = ko.observable();
    self.popLoading = ko.observable(false);

    self.plans = ko.observableArray([]);
    self.currentPlan = ko.observable('');
    
    self.initialize = function() {
        self.getSiteTexts();
        self.getPlans();
        self.getAddress();
        self.getServices();
        self.getCoverage();
        self.getFaq();
        self.getPopup();

        self.chooseCity(localStorage.getItem('chosenCityName'));
        self.configureBanner();
    };

    self.configureBanner = function() {
        self.bannerPosition(0);
        self.getBanners();
    };

    self.configureBannerTimer = function() {
        self.bannerTimer = setInterval(function() {
            self.changeBannerPos(true);
        }, 20000);
    };

    self.changeBannerPos = function(pos) {
        if (pos) {
            if (self.bannerPosition() < self.bannerContent().length - 1)
                self.bannerPosition(self.bannerPosition() + 1);
            else
                self.bannerPosition(0);
        } else {
            if (self.bannerPosition() > 0)
                self.bannerPosition(self.bannerPosition() - 1);
            else
                self.bannerPosition(self.bannerContent().length - 1);
        }

        clearInterval(self.bannerTimer);
        self.configureBannerTimer();
    };

    self.getBanners = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/banners?id=" + localStorage.getItem('chosenCity'),
        })
        .then(function (response) {
            if (response.success) {
                self.bannerContent.removeAll();
                
                response.response.sort(function(a, b) {
                    if (parseInt(a.ordenation) < parseInt(b.ordenation))
                        return -1;
                    if (parseInt(a.ordenation) > parseInt(b.ordenation))
                        return 1;
                    return 0;
                });

                ko.utils.arrayForEach(response.response, function(banner) {
                    var image = location.href.indexOf('novo') > -1 ? '/' + banner.image : banner.image;

                    var obj = {
                        id            : ko.observable(banner.id),
                        image         : ko.observable(image),
                        title         : banner.title,
                        subtitle      : banner.subtitle,
                        overlay_color : banner.overlay_color,
                        button        : banner.button,
                        url           : banner.url,
                        ordenation    : banner.ordenation,
                        text_position : banner.text_position,
                        video_link    : banner.video_link
                    };

                    self.bannerContent.push(obj);
                });

                if (self.bannerContent().length > 1)
                    self.configureBannerTimer();
            }
        });
    };

    self.getPopup = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/campaigns/" + localStorage.getItem('chosenCity'),
        })
        .then(function(response) {
            if (response.success) {
                setTimeout(function() {
                    debugger
                    self.showModal(true);
                    self.popup(response.response);
                    console.log(self.popup());

                    self.popup().width  = self.popup().width + 'px';
                    self.popup().height = self.popup().height + 'px';
                    self.marginLeft((parseInt(self.popup().width) / 2 * -1) + 'px');
                    self.marginTop((parseInt(self.popup().height) / 2 * -1) + 'px');
                }, 3000);
            }
        });
    };

    self.SendLeed = function() {
        if (!self.popName()) {
            alert("Para enviar seus dados, precisamos de seu nome!");
            return;
        }

        if (!self.popEmail() && !self.popPhone()) {
            alert("Para enviar seus dados, precisamos pelo menos do email e/ou telefone!");
            return;
        }

        self.popLoading(true);

        $.ajax({
            method: "POST",
            url: base + "api/v1/campaigns/" + localStorage.getItem('chosenCity'),
            data: {
                name: self.popName(),
                email: self.popEmail(),
                phone: self.popPhone(),
                campaign_id: self.popup().id
            }
        })
        .then(function (response) {
            self.popLoading(false);

            if (response.success) {
                alert('Informações enviadas com sucesso!');
                self.showModal(false);
                return;
            }

            alert(response.message);
        });
    };

    self.changeCity = function() {
        localStorage.clear();
        window.location.href = 'choose-city.php';
    };
    
    self.getSiteTexts = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/site-texts",
        })
        .then(function (response) {
            if (response.success) {
                var result = response.response.reduce(function (r, a) {
                    r[a.section] = r[a.section] || [];
                    r[a.section].push(a);
                    return r;
                }, Object.create(null));
                
                self.siteTexts(result);
            }

            $("#load").fadeOut();
            $("#loading").delay(0).fadeOut("slow");
        });
    };

    self.getPlans = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/plans-per-city/" + localStorage.getItem('chosenCity'),
        })
        .then(function (response) {
            if (response.success) {
                ko.utils.arrayForEach(response.response, function(plan) {
                    plan.offers.forEach(function (offer) {
                        offer.price = parseFloat(offer.price);
                        offer.strippedDetails = offer.details.replace(/<[^>]+>/g, '');
                        offer.items = JSON.parse(offer.items);
                    });

                    plan.offers.sort(function(a, b) {
                        if (a.position < b.position)
                            return -1;
                        
                        if (a.position > b.position)
                            return 1;

                        return 0;
                    });

                    plan.position = parseInt(plan.position);
                });
                
                self.plans(response.response);

                self.plans().sort(function(a, b) {
                    if (a.position < b.position)
                        return -1;
                    if (a.position > b.position)
                        return 1;
                });
                
                if (self.plans().length > 0)
                    self.currentPlan(self.plans()[0]);
            }
        });
    };

    self.sortedPlans = ko.computed(function() {
        return self.plans().sort(function(a, b) {
            if (a.position < b.position)
                return -1;
            if (a.position > b.position)
                return 1;
        });
    });

    self.showPlans = function(index) {
        self.currentPlan(self.sortedPlans()[index]);
    };

    self.canShow = function(data) {
        return data.hasOwnProperty('details') && data.details != null && data.details != undefined && data.strippedDetails != '';
    }

    self.formatPrice = function(price) {
        return 'R$ ' + price.toFixed(2).toString().replace('.', ',');
    };

    self.getAddress = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/address-per-city/" + localStorage.getItem('chosenCity'),
        })
        .then(function (response) {
            if (response.success) {
                self.address(response.response);                
            }                    
        });
    };

    self.getServices = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/services",
        })
        .then(function (response) {
            if (response.success) {
                self.services(response.response);
            }                    
        });
    };

    self.getCoverage = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/coverage",
        })
        .then(function (response) {
            if (response.success) {
                var result = response.response.reduce(function (r, a) {
                    r[a.type] = r[a.type] || [];
                    r[a.type].push(a);
                    return r;
                }, Object.create(null));
                
                self.coverage(result);

                $('.iq-progress-bar > span').each(function() {
                    var $this = $(this);
                    var width = $(this).data('percent');
                    $this.css({
                        'transition': 'width 2s'
                    });
                    setTimeout(function() {
                        $this.appear(function() {
                            $this.css('width', width + '%');
                        });
                    }, 500);
                });
            }
        });
    };

    self.getFaq = function() {
        $.ajax({
            method: "GET",
            url: base + "api/v1/faq",
        })
        .then(function (response) {
            if (response.success) {
                self.faq(response.response);

                console.log(self.faq());

                $('.iq-accordion .iq-ad-block .ad-details').hide();
                $('.iq-accordion .iq-ad-block:first').addClass('ad-active').children().slideDown('slow');
                $('.iq-accordion .iq-ad-block').on("click", function() {
                    if ($(this).children('div').is(':hidden')) {
                        $('.iq-accordion .iq-ad-block').removeClass('ad-active').children('div').slideUp('slow');
                        $(this).toggleClass('ad-active').children('div').slideDown('slow');
                    }
                });
            }                    
        });
    };

    self.signPlan = function(data) {
        data.cityName = localStorage.getItem('chosenCityName');
        data.cities_id = localStorage.getItem('chosenCity');
        self.chosenPlan(data);
        self.customer({
            name: '',
            email: '',
            phone: '',
            cpf: '',
            message: '',
            plans_offers_id: data.id,
            cities_id: localStorage.getItem('chosenCity')
        });
        $('#cpf').mask('000.000.000-00');
    };

    self.savePotentialCustomer = function() {
        if (self.customer().name == '') {
            alert('Você precisa inserir seu nome para assinar um plano');
            return
        }
        
        if (self.customer().email == '') {
            alert('Você precisa inserir seu email para assinar um plano');
            return
        }
        
        if (self.customer().phone == '') {
            alert('Você precisa inserir seu telefone para assinar um plano');
            return
        }
        
        if (self.customer().cpf == '') {
            alert('Você precisa inserir seu CPF para assinar um plano');
            return
        }

        self.customer().cpf = self.customer().cpf.replace('.', '').replace('-', '').replace('.', '');

        if (!self.isValidCpf(self.customer().cpf)) {
            alert('O CPF inserido é inválido... Verifique os dígitos e envia um CPF válido.');
            return
        }

        $.ajax({
            method: "POST",
            url: base + "api/v1/potential-customers",
            data: self.customer()
        })
        .then(function (response) {
            if (response.success) {
                alert('Enviamos seu contato. Em breve a VB Telecom entrará em contato com você.');
                $('#sign-up-modal').modal('hide');
                return;
            }

            alert(response.message);
        });
    };

    self.isValidCpf = function(cpf) {
        var Soma;
        var Resto;
        Soma = 0;
          
        if (cpf == "00000000000"
            || cpf == "11111111111"
            || cpf == "22222222222"
            || cpf == "33333333333"
            || cpf == "44444444444"
            || cpf == "55555555555"
            || cpf == "66666666666"
            || cpf == "77777777777"
            || cpf == "88888888888"
            || cpf == "99999999999")
            return false;
             
        for (var i = 1; i <= 9; i++) {
            Soma = Soma + parseInt(cpf.substring(i-1, i)) * (11 - i);
        }

        Resto = (Soma * 10) % 11;
           
        if ((Resto == 10) || (Resto == 11))
            Resto = 0;
        
        if (Resto != parseInt(cpf.substring(9, 10)))
            return false;

        Soma = 0;
        
        for (var i = 1; i <= 10; i++) {
            Soma = Soma + parseInt(cpf.substring(i-1, i)) * (12 - i);
        }

        Resto = (Soma * 10) % 11;

        if ((Resto == 10) || (Resto == 11))
            Resto = 0;
        
        if (Resto != parseInt(cpf.substring(10, 11)))
            return false;
        
        return true;
    }

    self.youtubeParser = function(url) {
        if (!url) return '';
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length == 11) ? match[7] : false;
    }

    self.setPlanDescription = function(desc) {
        self.planDescription(desc);
    };

    self.initialize();
};

ko.applyBindings(new ViewModel());